@import '../../_assets/varabile/varabile.scss';
.partnerLayout {
	padding: 4rem;
	max-width: 1300px;
	margin: 0 auto;
	
	h1 {
		text-align: center;
		padding: 2rem 0;
		color: $blue;
	}
	article {
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin: 0 auto;
		figure {
			width: 33%;
		}
	}
}

@media (max-width: 700px) {
	.partnerLayout {
		article {
			width: 85%;
			display: block;
			figure {
				width: 60%;
				margin: 1rem auto;
			}
		}

	}
}

@media (max-width: 450px) {
	.partnerLayout {
		article {
			width: 100%;
		}
	}
}
