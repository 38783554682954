@import '../_assets/varabile/varabile.scss';
.home{
	background-color: $darkWhite;
    .HeadImg{
        overflow: hidden;
        // max-height: 60vh;
        video{
            display: block;
            width: 100%;
            margin-top: -8%;
        }
    }
    .productsLayout {
        width: 100%;
        margin: 0 auto;
        padding: 3rem;
        background: url('../_assets/imgs/weis.jpg') no-repeat;
	hr {
		height: 3px;
		background-color: $blue;
	}
    }
    .servicesContainer{
        padding: 3rem;
        background-color: $blue;
    }
}

