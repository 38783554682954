@import '../_assets/varabile/varabile.scss';
.servicesContanier {
	background-color: $darkWhite;
	padding: 3rem;
	background: url("../_assets/imgs/weissand.jpg") no-repeat; 
			background-size: cover;
	.services {
		max-width: 1600px;
		width: 70%;
		margin: 0 auto;
		text-align: center;
		color: $blue;
		.servicesPageInfo {
			h1 {
				padding: 2rem 0;
			}
		}
		.servicesCollection {
			display: flex;
			flex-wrap: wrap;
			figure {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 50%;
				.serviceImg {
					width: 8%;
					margin: 2rem 0;
				}
				figcaption {
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					p {
						color: $blue;
						width: 90%;
						margin: 0 auto;
						padding: 1rem 0;
					}
					.greenBtn {
						display: inline-block;
						font-size: .7rem;
						padding: .4rem 1rem;
						color: $blue;
						margin-top: auto;
						align-self: center;
						border: 1px solid $blue;
						transition: background-color .3s ease-in-out;
						&:hover {
							color: $white;
							background-color: $blue;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.services {
		width: 100%;
	}
}
@media (max-width: 700px) {
	.services {
		.servicesCollection {
			flex-direction: column;
			align-items: center;
			figure {
				width: unset;
			}
		}
	}
}
