@import '../../_assets/varabile/varabile.scss';
.hamburgerBtn,
.closeBtn {
	width: 44px;
}
.mobNav {
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 84%;
	top: 0;
	right: 0;
	bottom: 0;
	background: #042c4b;
	padding: 1rem;
	z-index: 600;
	transform: translateX(100%);
	transition: transform 0.2s ease-in-out;

	&.open {
		transform: translateX(0);
	}
	&.left {
		right: unset;
		left: 0;
		transform: translateX(-100%);
	}
	.closeBtn {
		margin-left: auto;
	}
	li {
		padding: 1rem;
		&:last-of-type {
			padding-bottom: 0;
		}
	}
}
